import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '../users/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {Unit} from '../users/user';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  displayedColumns = ['expand', 'name', 'orgUnitPath', 'directMembersCount', 'assign'];
  dataSource: MatTableDataSource<Unit>;
  expandedUnitId: string | null = null;
  members: string[] = [];
  isLoading: boolean = false;
  isDataLoaded: boolean = false;

  @Input() units: Unit[] = [];
  @Input() updating? = false;
  @Output() clicked = new EventEmitter<string>();
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
  }

  loadData() {
    if (this.isDataLoaded) {
      return;
    }

    this.isLoading = true;
    this.userService.units().subscribe(
      data => {
        this.dataSource.data = data;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.isDataLoaded = true;
      }
    );
  }

  toggleMembers(unitId: string) {
    const selectedUnit = this.dataSource.data.find(unit => unit.orgUnitPath === unitId);

    if (selectedUnit && selectedUnit.directMembersCount > 0) {
      if (this.expandedUnitId === unitId) {
        this.expandedUnitId = null;
        this.members = [];
      } else {
        this.expandedUnitId = unitId;
        this.members = selectedUnit.members;
      }
    }
  }

  assign(orgUnitPath: string) {
    return this.clicked.emit(orgUnitPath);
  }
}
