import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv';
import { ClientWithStats } from '../../client/client.interface';
import { Employee, EmployeeListItem } from '../../employees/employees.interface';

@Injectable({providedIn: 'root'})
export class ReportsService {

  constructor(private translate: TranslateService) {
  }

  generateClientsReport = (clients: ClientWithStats[]) => {
    const reportData = clients.map(
      ({name, domain, createdAt, googleAdminEmail, stats, planId, trialFinishDate}) =>
        ({
          name: name || '',
          domain,
          createdAt,
          googleAdminEmail,
          installedCurrentWeek: stats.current.numberOfEmployeesWithSignatureInstalled,
          totalCurrentWeek: stats.current.numberOfEmployees,
          installedPreviousWeek: stats.previous.numberOfEmployeesWithSignatureInstalled,
          totalPreviousWeek: stats.previous.numberOfEmployees,
          planId,
          trialFinishDate: trialFinishDate || ''
        })
    );

    const headerKeys = ['client.name', 'client.domain', 'client.createdAt', 'client.googleAdminEmail', 'client.installedCurrentWeek', 'client.totalCurrentWeek', 'client.installedPreviousWeek', 'client.totalPreviousWeek', 'client.subscription', 'client.trialFinishDate'];
    this.generateReport(reportData, headerKeys.map(item => this.translate.instant(item)), 'menu.clients');
  };

  generateEmployeesReport = (employees: (Employee | EmployeeListItem)[], employeeSettings: string[]) => {
    const groupedEmployees = employees.reduce((acc, employee) => {
      if ('isAlias' in employee && employee.isAlias) {
        const owner = acc.find(e => e.fullName === employee.fullName);
        if (owner) {
          owner.aliases = owner.aliases || [];
          owner.aliases.push(employee);
        }
      } else {
        acc.push({ ...employee, aliases: [] });
      }
      return acc;
    }, []);
  
    const reportData = [];
    groupedEmployees.forEach(owner => {
      reportData.push({
        givenName: owner.givenName || '',
        familyName: owner.familyName || '',
        email: owner.email,
        typ: this.translate.instant('employee.employee'),
        excludedFromSignatures: owner.excludedFromSignatures,
        createdAt: owner.createdAt,
        modifiedAt: owner.modifiedAt,
        ...employeeSettings.reduce((ac, settingName) => ({
          ...ac,
          [settingName]: owner.dynamicVariables?.[settingName] || ''
        }), {})
      });
  
      owner.aliases.forEach(alias => {
        const [givenName, ...familyNameParts] = alias.fullName?.split(' ') || [];
        const familyName = familyNameParts.join(' ');
  
        reportData.push({
          givenName: givenName || '',
          familyName: familyName || '',
          email: alias.email || '',
          typ: this.translate.instant('employee.alias'),
          excludedFromSignatures: '',
          createdAt: '',
          modifiedAt: '',
          ...employeeSettings.reduce((ac, settingName) => ({
            ...ac,
            [settingName]: ''
          }), {})
        });
      });
    });
  
    const headers = [
      'employee.familyName',
      'employee.givenName',
      'email',
      'employee.type',
      'employee.excludedFromSignatures',
      'employee.createdAt',
      'employee.modifiedAt',
      ...employeeSettings
    ].map(item => this.translate.instant(item));
  
    this.generateReport(reportData, headers, 'menu.employees');
  };

  generateReport = (reportData: any, headers: string[], nameKey) => {
    new ngxCsv(
      reportData,
      this.translate.instant(nameKey),
      {
        headers
      }
    );
  };

}
