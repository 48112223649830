import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss']
})
export class AvatarUploaderComponent implements OnInit {

  @Input() avatarUploadUrl: string;
  @Input() thumbnailPhotoUrl: string;
  @Input() localAvatar: boolean;

  useCustomAvatarEnabled = false;

  ngOnInit() {
    this.useCustomAvatarEnabled = this.localAvatar;
  }

  toggleUseCustomAvatar(checked: boolean) {
    this.useCustomAvatarEnabled = checked;
  }

}
