import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddDisclaimerObject, Disclaimer, Disclaimers } from './disclaimers.interface';
import { AddonsResponse, TypedAddon } from '../addons.interface';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DisclaimersService {

  constructor(private http: HttpClient) {
  }

  getDisclaimers = (): Observable<Disclaimers> => {
    return this.http.get<AddonsResponse>('/addons')
      .pipe(
        map((addons) => addons?.DISCLAIMER?.map(disclaimer => ({
          id: disclaimer.id,
          modifiedAt: disclaimer.modifiedAt,
          isUsed: disclaimer.isUsed,
          ...disclaimer.data,
        } as Disclaimer))),
        map(disclaimers => ({ disclaimers }))
      );

  };

  getDisclaimerById = (id: string): Observable<Disclaimer> => {
    return this.getDisclaimers().pipe(
      map((disclaimers) => disclaimers.disclaimers.find((disclaimer) => disclaimer.id === id))
    );
  };

  changeDisclaimer = (payload: Disclaimer): Observable<any> => {
    return this.http.put(`/addons/${ payload.id }`, payload);
  };

  removeDisclaimer = (id: string): Observable<any> => {
    return this.http.delete(`/addons/${ id }`);
  };

  addDisclaimer = (payload: AddDisclaimerObject): Observable<any> => {
    return this.http.post<TypedAddon<Disclaimer>>(`/addons`, payload)
      .pipe(
        map((resp) => ({ id: resp.id, ...resp.data }))
      );
  }

}
