<h1 mat-dialog-title translate>partner.admins.new</h1>

<div mat-dialog-content class="content">
  <form [formGroup]="form" (submit)="submit()" class="form">
    <mat-form-field appearance="fill">
      <mat-label translate>partner.email</mat-label>
      <input type="text" matInput formControlName="email">
      <mat-error *ngIf="form.hasError('required', ['email'])">{{ 'fieldRequired' | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="close()">{{'cancel' | translate}}</button>
  <app-progress-button label="save" (buttonClick)="submit()" [loading]="sendingRequest"/>
</div>
