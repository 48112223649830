import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Employee, EmployeeListItem } from 'src/app/employees/employees.interface';
import { EmployeesService } from '../../employees/employees.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-submit-user',
  templateUrl: './submit-user.component.html',
  styleUrls: ['./submit-user.component.scss']
})
export class SubmitUserComponent implements OnInit, OnDestroy {

  employees: Employee[] = [];
  aliases: EmployeeListItem[] = [];
  allData: any[] = [];
  combinedData: any[] = [];
  searchControl = new UntypedFormControl('');
  selectedItems: any[] = [];
  displayedColumns: string[] = ['fullName', 'email', 'type', 'select'];

  @Input() updating: boolean;
  @Output() submitted = new EventEmitter();

  private unsubscribe$ = new Subject<void>();

  constructor(private employeesService: EmployeesService) {}

  ngOnInit(): void {
    this.employeesService.getNotExcludedEmployeesWithAliases().subscribe(data => {
      this.allData = data.reduce((acc, employee) => {
        acc.push({ ...employee, isAlias: false });
        if (employee.aliases && employee.aliases.length > 0) {
            const aliasItems = employee.aliases.map(alias => ({
                ...alias,
                fullName: employee.fullName,
                email: alias.email,
                isAlias: true,
            }));
            acc.push(...aliasItems);
        }
        return acc;
      }, []);

      this.combinedData = [...this.allData];
    });

    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(value => this.filterData(value));
  }

  filterData(filterValue: string): void {
    const lowerCaseFilter = filterValue.trim().toLowerCase();
    if (lowerCaseFilter) {
      this.combinedData = this.allData.filter(element =>
        element.fullName.toLowerCase().includes(lowerCaseFilter) ||
        element.email.toLowerCase().includes(lowerCaseFilter)
      );
    } else {
      this.combinedData = [...this.allData];
    }
  }

  toggleSelection(element: any): void {
    const index = this.selectedItems.findIndex(item => item.email === element.email);
    if (index === -1) {
      this.selectedItems.push(element);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  isAllSelected(): boolean {
    return this.combinedData.length > 0 && this.selectedItems.length === this.combinedData.length;
  }

  isAnySelected(): boolean {
    return this.selectedItems.length > 0 && this.selectedItems.length < this.combinedData.length;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selectedItems = [];
    } else {
      this.selectedItems = [...this.combinedData];
    }
  }

  clearSelection(): void {
    this.selectedItems = [];
  }

  submit(): void {
    const formData = {
      emails: this.selectedItems.filter(item => !item.isAlias).map(item => item.email),
      alias: this.selectedItems.filter(item => item.isAlias).map(item => item.email)
    };

    this.submitted.emit(formData);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
