import {createSelector} from '@ngrx/store';
import {SignaturesState} from './signatures.interface';
import {
  CHANGE_SIGNATURE_SUCCESS,
  GET_SIGNATURES_SUCCESS,
  INSTALL_SIGNATURE_SUCCESS,
  REINSTALL_SIGNATURES_FAILURE,
  REINSTALL_SIGNATURES_SUCCESS,
  REMOVE_SIGNATURE_SUCCESS,
  SignaturesActions,
  UNINSTALL_SIGNATURE_SUCCESS
} from './signatures.actions';

export const initialState: SignaturesState = {
  signatures: null
};

export function signaturesReducer(
  state: SignaturesState = initialState,
  action: SignaturesActions
): SignaturesState {
  switch (action.type) {
    case GET_SIGNATURES_SUCCESS:
      return {
        ...state,
        signatures: action.payload
      };
    case REMOVE_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatures: state.signatures.filter(signature => signature.id !== action.payload)
      };
    case INSTALL_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatures: state.signatures.map(signature => (signature.id === action.payload
            ? {...signature, numberOfInstallations: 1}
            : signature
        ))
      };
    case UNINSTALL_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatures: state.signatures.map(signature => (signature.id === action.payload
            ? {...signature, numberOfInstallations: 0}
            : signature
        ))
      };
    case CHANGE_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatures: state.signatures.map(signature => (signature.id === action.payload.id
            ? {...signature, name: action.payload.body, content: action.payload.parsedBody}
            : signature
        ))
      };
    case REINSTALL_SIGNATURES_SUCCESS:
      return state;
    case REINSTALL_SIGNATURES_FAILURE:
      return state;
    default: {
      return state;
    }
  }
}

export const getSignaturesRootState = state => state.signatures;
export const getSignaturesState = (state: SignaturesState) => state.signatures;

export const getSignaturesSelector = createSelector(getSignaturesRootState, getSignaturesState);
