<small>
  {{'footers.aliasInfo' | translate}}
  <a href="https://support.google.com/mail/answer/22370" target="_blank">
    {{'more' | translate}}
  </a>
</small>

<form (ngSubmit)="submit()" class="form">
  <mat-form-field appearance="fill" class="search-field">
    <mat-label>{{ 'employee.search' | translate }}</mat-label>
    <input matInput [formControl]="searchControl" placeholder="{{ 'employee.search' | translate }}">
  </mat-form-field>
  
  <div class="employee-table-container">
    <table mat-table [dataSource]="combinedData">
    
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>{{ 'employee.fullName' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ 'employee.email' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{ 'employee.type' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.isAlias ? ('employee.alias' | translate) : ('employee.employee' | translate) }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="isAllSelected()"
            [indeterminate]="isAnySelected()"
            [disabled]="updating">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? toggleSelection(element) : null"
            [checked]="selectedItems.includes(element)"
            [disabled]="updating">
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleSelection(row)">
      </tr>
    </table>
  </div>
  
  <div class="actions">
    <button mat-raised-button [disabled]="updating || selectedItems.length === 0" (click)="submit()">
      {{ (updating ? 'footers.setting' : 'footers.set') | translate }}
    </button>
  </div>
</form>
