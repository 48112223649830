<div class="avatar-wrapper">
  <div class="toggle-avatar">
    <mat-slide-toggle
      [checked]="useCustomAvatarEnabled"
      [disabled]="localAvatar"
      (change)="toggleUseCustomAvatar($event.checked)">
      {{'employee.avatar.customAvatar' | translate}}
    </mat-slide-toggle>
  </div>
  <mat-accordion>
    <mat-expansion-panel hideToggle [disabled]="!useCustomAvatarEnabled" [expanded]="useCustomAvatarEnabled">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'employee.avatar.label' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-image-processing 
        [imageUploadUrl]="avatarUploadUrl" 
        [thumbnailPhotoUrl]="thumbnailPhotoUrl"
        [localAvatar]="localAvatar">
      </app-image-processing>
    </mat-expansion-panel>
  </mat-accordion>
</div>
