<app-loading *ngIf="loading"></app-loading>

<div *ngIf="!loading">
  <mat-card-header>
    <button mat-raised-button (click)="fileInput.click()" color="accent">
      <ng-container>{{'employee.avatar.selectFile' | translate}}</ng-container>
    </button>
  </mat-card-header>

  <mat-card-content>
    <div class="mat-card-content">
      <input hidden type="file" #fileInput (change)="onFileSelect($event)">

      <div *ngIf="localAvatar && !uploadedImage" class="thumbnail-wrapper">
        <img [src]="thumbnailPhotoUrl" class="thumbnail-image">
      </div>

      <div class="image-cropper-wrapper" *ngIf="finalImage">
        <image-cropper
          [imageFile]="finalImage"
          [maintainAspectRatio]="true"
          [resizeToWidth]="256"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="false"
          [canvasRotation]="0"
          [alignImage]="'left'"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="imageCropped($event)"
          [backgroundColor]="backgroundColor"
        ></image-cropper>
      </div>

      <div *ngIf="transparentAvatar" class="background-actions">
        <button mat-stroked-button (click)="drawAvatarWithColor('')">{{'employee.avatar.transparent' | translate}}</button>
        <button mat-stroked-button (click)="drawOriginalAvatar()">{{'employee.avatar.original' | translate}}</button>
        <button mat-stroked-button (click)="drawAvatarWithColor('black')">{{'employee.avatar.black' | translate}}</button>
        <button mat-stroked-button (click)="drawAvatarWithColor('white')">{{'employee.avatar.white' | translate}}</button>
        <br/>
      </div>

      <form *ngIf="transparentAvatar" class="custom-color-form">
        <mat-form-field>
          <mat-label>{{"employee.avatar.customColor" | translate}}</mat-label>
          <input type="text" matInput [formControl]="customBackgroundColor">
        </mat-form-field>
      </form>
    </div>

    <div class="save-button-wrapper">
      <button *ngIf="uploadedImage" mat-raised-button (click)="saveNewImage()" color="primary">
        {{'employee.avatar.save' | translate}}
      </button>
    </div>
  </mat-card-content>
</div>
