<ng-template #snackbarTemplate>
  <div class="snackbar-wrapper">
    <mat-spinner *ngIf="currentState?.status === 'IN_PROGRESS'" diameter="20" color="accent"></mat-spinner>

    <ng-container *ngIf="currentState as state">

      <div class="in-progress" *ngIf="currentState.status === 'IN_PROGRESS'">

        <div *ngIf="(state.totalAliases || 0) > 0">
          {{'installation.inProgressAliases' | translate: {
            installedAliases: installedAliasesCount,
            totalAliases: state.totalAliases || 0
          } }}
        </div>

        <div *ngIf="(state.totalEmployees || 0) > 0">
          {{'installation.inProgressEmployees' | translate: {
            installedEmployees: installedEmployeesCount,
            totalEmployees: state.totalEmployees || 0
          } }}
        </div>

        <div>{{ 'installation.update' | translate: {date: lastUpdate} }}</div>
      </div>

      <div class="finished" *ngIf="currentState.status === 'COMPLETED_SUCCESSFULLY'">
        <mat-icon>check</mat-icon>
        <span class="finished-label">
          {{ 'installation.finished' | translate: {installations: state.installations.length, total: totalInstallations} }}
        </span>

        <button class="cancel" mat-stroked-button (click)="close()">{{ 'close' | translate }}</button>
      </div>

      <div class="finished-error" *ngIf="currentState.status === 'COMPLETED_WITH_ERROR'">
        <mat-icon>warning</mat-icon>
        <span class="finished-label">{{ 'installation.error' | translate: {errors: errors.length} }}</span>
        <button mat-stroked-button (click)="showErrors()">{{ 'installation.showErrors' | translate }}</button>
      </div>

    </ng-container>

  </div>
</ng-template>
