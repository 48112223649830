<div *ngIf="employee">
  <mat-toolbar>
    {{ employee.fullName }} ({{ employee.email }})
  </mat-toolbar>

  <div class="signature-preview" *ngIf="parsedSignature">
    <mat-card appearance="outlined" class="signature">
      <mat-card-content>
        <div class="signature-wrapper">
          <div [innerHTML]="parsedSignature | safeHtml" class="signature-content"></div>
        </div>
        <div class="copy-signature">
          <a (click)="copySignatureToClipboard()">
            <mat-icon>content_copy</mat-icon>
            {{ 'employee.copySignature' | translate }}
          </a>
          <a (click)="downloadSignatureOutlook()">
            <mat-icon>download</mat-icon>
            {{ 'employee.downloadSignatureOutlook' | translate }}
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="employee-edit-wrapper">
    <form [formGroup]="form" class="form" (ngSubmit)="saveAndInstall()">

      <div class="data-wrapper">
        <label>{{ 'employee.createdAt' | translate }}:</label>
        {{ employee.createdAt | localizedDate:'medium' }}
      </div>

      <div class="data-wrapper" >
        <label>{{ 'employee.modifiedAt' | translate }}:</label>
        {{ employee.modifiedAt | localizedDate:'medium' }}
      </div>

      <div class="data-wrapper" *ngIf="employee.signatureInstallationAt">
        <label>{{ 'employee.signatureInstallationAt' | translate }}:</label>
        {{ employee.signatureInstallationAt | localizedDate:'medium' }}
      </div>

      <div class="data-wrapper">
        <label>{{ 'employee.excludedFromSignatures' | translate }}:</label>
        <mat-radio-group formControlName="excludedFromSignatures"
                         [disabled]="!employee.canBeExcludedFromSignatures"
                         [matTooltip]="employee.canBeExcludedFromSignatures ? undefined : 'employee.cannotBeExcludedFromSignatures' | translate"
        >
          <mat-radio-button [disabled]="!employee.canBeExcludedFromSignatures" [value]="true">{{ 'yes' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
          <mat-error *ngIf="form.hasError('required', ['excludedFromSignatures'])">
            {{ 'fieldRequired' | translate }}
          </mat-error>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'employee.givenName' | translate }}</mat-label>
        <input matInput formControlName="givenName" type="text">
        <mat-error *ngIf="form.hasError('required', ['givenName'])">{{ 'fieldRequired' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'employee.familyName' | translate }}</mat-label>
        <input matInput formControlName="familyName" type="text">
        <mat-error *ngIf="form.hasError('required', ['familyName'])">{{ 'fieldRequired' | translate }}</mat-error>
      </mat-form-field>

      <div [formGroup]="dynamicVariables" class="dynamic-fields">
        <mat-form-field appearance="fill" *ngFor="let employSetting of employeeSettings">
          <mat-label>{{ employSetting.label || ('employee.signatureVariables.' + employSetting.name | translate) }}</mat-label>
          <input matInput [formControlName]="employSetting.name" type="text">
          <mat-error *ngIf="dynamicVariables.hasError('required', [employSetting.name])">
            {{ 'fieldRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="dynamicVariables.hasError('phoneNumberInvalid', [employSetting.name])">
            {{ 'phoneNumberInvalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="actions">
        <a mat-raised-button (click)="goToEmployeeList()">{{'cancel' | translate}}</a>
        <button mat-stroked-button color="primary" type="submit">{{'saveAndInstall' | translate}}</button>
        <a mat-raised-button color="primary" (click)="save()">{{ 'save' | translate }}</a>
      </div>
    </form>

    <mat-card appearance="outlined" *ngIf="employee.picture">
      <img mat-card-image [src]="employee.picture" alt="">
    </mat-card>

  </div>

  <div class="avatar-wrapper">
    <app-avatar-uploader 
      avatarUploadUrl="/employees/{{employee.email}}/avatar"
      [thumbnailPhotoUrl]="employee.picture" 
      [localAvatar]="employee.localAvatar">
    </app-avatar-uploader>
  </div>

</div>

