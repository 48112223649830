import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { PartnerAdmin, PartnerAdminTableRow } from './partner-admin-table.interace';

@Component({
  selector: 'app-partner-admin-table',
  templateUrl: './partner-admin-table.component.html',
  styleUrls: ['./partner-admin-table.component.scss']
})
export class PartnerAdminTableComponent implements OnInit {
  public displayedColumns = ['email'];
  public admins: PartnerAdmin[];

  @Input()
  public data: Observable<PartnerAdminTableRow[]>;

  dataSource: MatTableDataSource<PartnerAdminTableRow>;

  constructor() {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
  }

  loadData() {
    this.data.subscribe(data => {
      this.dataSource.data = data;
    });
  }

}
