import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AddBannerAction } from '../banners.actions';
import { Image } from '../../../shared/image-uploader/image.interface';
import { ImageService } from '../../../shared/image-uploader/image.service';

@Component({
  selector: 'app-banner-add',
  templateUrl: './banner-add.component.html',
  styleUrls: ['./banner-add.component.scss']
})
export class BannerAddComponent implements OnInit {

  @Input() onClose;

  file;
  public form: UntypedFormGroup;

  constructor(
    private store: Store,
    public imageService: ImageService
  ) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      file: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      url: new UntypedFormControl('', Validators.required),
      utm: new UntypedFormControl('utm_source=gsignature', Validators.required),
      imageWidth: new UntypedFormControl('', Validators.min(0)),
      imageHeight: new UntypedFormControl('', Validators.min(0)),
      originalImageWidth: new UntypedFormControl('', Validators.min(0)),
      originalImageHeight: new UntypedFormControl('', Validators.min(0)),
    });
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new AddBannerAction(this.form.value));
      this.onClose();
    } else {
      this.form.markAllAsTouched();
    }
  }

  fileChange = (file: Image) => {
    this.file = file.file;
    const width = file.width || this.form.value.originalImageWidth;
    const height = file.height || this.form.value.originalImageHeight;

    this.form.patchValue({
      file:  file.file,
      originalImageWidth: width,
      originalImageHeight: height,
      imageWidth: width,
      imageHeight: height,
    });
  }

  cancel = () => {
    this.fillInitialForm();
    this.onClose();
  }

}
