<div *ngIf="!editMode">
  {{'addons.links.name' | translate}}: {{link.name}}
  <br/>
  {{'addons.links.url' | translate}}: {{link.url}}
  <br/>
  {{'addons.links.text' | translate}}: {{link.text}}
  <br/>
  <span *ngIf="link.modifiedAt">
    {{ 'modifiedAt' | translate }}: {{ link.modifiedAt | localizedDate:'medium' }}
  </span>
</div>

<form [formGroup]="form" class="form" (ngSubmit)="changeLink()" *ngIf="editMode">
  <span *ngIf="link.modifiedAt">
    {{ 'modifiedAt' | translate }}: {{ link.modifiedAt | localizedDate:'medium' }}
  </span>
  <mat-form-field appearance="fill">
    <mat-label>{{'addons.links.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{'addons.links.url' | translate}}</mat-label>
    <input matInput formControlName="url" type="text">
    <mat-error *ngIf="form.hasError('required', ['url'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{'addons.links.text' | translate}}</mat-label>
    <input matInput formControlName="text" type="text">
    <mat-error *ngIf="form.hasError('required', ['text'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>
</form>

<mat-divider></mat-divider>
<div class="actions">
  <mat-icon *ngIf="editMode" title="{{'cancel' | translate}}" (click)="cancelEditLink()">cancel</mat-icon>
  <mat-icon *ngIf="editMode" title="{{'save' | translate}}" (click)="changeLink()">save</mat-icon>
  <mat-icon *ngIf="!editMode" title="{{'edit' | translate}}" (click)="changeEditMode()">edit</mat-icon>
  <mat-icon *ngIf="!editMode && !link.isUsed" title="{{'delete' | translate}}" (click)="removeLink(link.id)">delete_outline</mat-icon>
</div>
