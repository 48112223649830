import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  CHANGE_SIGNATURE,
  ChangeSignatureAction,
  ChangeSignatureFailureAction,
  ChangeSignatureSuccessAction,
  DUPLICATE_SIGNATURE,
  DUPLICATE_SIGNATURE_SUCCESS,
  DuplicateSignatureAction,
  DuplicateSignatureFailureAction,
  DuplicateSignatureSuccessAction,
  GET_SIGNATURES,
  GetSignaturesAction,
  GetSignaturesFailureAction,
  GetSignaturesSuccessAction,
  INSTALL_SIGNATURE,
  InstallSignatureAction,
  InstallSignatureFailureAction,
  InstallSignatureSuccessAction,
  REMOVE_SIGNATURE,
  REINSTALL_SIGNATURES,
  ReinstallSignaturesAction,
  ReinstallSignaturesFailureAction,
  ReinstallSignaturesSuccessAction,
  RemoveSignatureAction,
  RemoveSignatureFailureAction,
  RemoveSignatureSuccessAction,
  SET_DEFAULT_SIGNATURE,
  SetDefaultSignatureAction,
  SetDefaultSignatureFailureAction,
  SetDefaultSignatureSuccessAction,
  UNINSTALL_SIGNATURE,
  UninstallSignatureAction,
  UninstallSignatureFailureAction,
  UninstallSignatureSuccessAction
} from './signatures.actions';
import {SignaturesService} from './signatures.service';
import {ChangeSignatureObject, Signature} from './signatures.interface';
import { scrollToBottom } from '../shared/utils/window.utils';

@Injectable()
export class SignaturesEffects {

  getSignatures$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_SIGNATURES),
      switchMap(() => {
        return this.signaturesService.getSignatures().pipe(
          switchMap((response: Signature[]) => {
            return [new GetSignaturesSuccessAction(response)];
          }),
          catchError(error => of(new GetSignaturesFailureAction(error)))
        );
      })
    );
  });

  changeSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_SIGNATURE),
    map((action: ChangeSignatureAction) => action.payload),
    switchMap((payload: ChangeSignatureObject) => {
      return this.signaturesService.changeSignature(payload).pipe(
        switchMap(() => {
          return [new ChangeSignatureSuccessAction(payload)];
        }),
        catchError(error => of(new ChangeSignatureFailureAction(error)))
      );
    })
  ));

  removeSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_SIGNATURE),
    map((action: RemoveSignatureAction) => action.payload),
    switchMap((payload: string) => {
      return this.signaturesService.removeSignature(payload).pipe(
        switchMap(() => {
          return [new RemoveSignatureSuccessAction(payload)];
        }),
        catchError(error => of(new RemoveSignatureFailureAction(error)))
      );
    })
  ));


  installSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(INSTALL_SIGNATURE),
    map((action: InstallSignatureAction) => action.payload),
    switchMap((payload: string) => {
      return this.signaturesService.installSignature(payload).pipe(
        switchMap(() => {
          return [new InstallSignatureSuccessAction(payload)];
        }),
        catchError(error => of(new InstallSignatureFailureAction(error)))
      );
    })
  ));

  uninstallSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UNINSTALL_SIGNATURE),
    map((action: UninstallSignatureAction) => action.payload),
    switchMap((payload: string) => {
      return this.signaturesService.uninstallSignature(payload).pipe(
        switchMap(() => {
          return [new UninstallSignatureSuccessAction(payload)];
        }),
        catchError(error => of(new UninstallSignatureFailureAction(error)))
      );
    })
  ));

  setDefaultSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SET_DEFAULT_SIGNATURE),
    map((action: SetDefaultSignatureAction) => action.payload),
    switchMap((payload: string) => {
      return this.signaturesService.setDefaultForNewEmployee(payload).pipe(
        switchMap(() => {
          return [new GetSignaturesAction(), new SetDefaultSignatureSuccessAction()];
        }),
        catchError(error => of(new SetDefaultSignatureFailureAction(error)))
      );
    })
  ));

  duplicateSignature$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DUPLICATE_SIGNATURE),
      map((action: DuplicateSignatureAction) => action.payload),
      switchMap((payload: string) => {
        return this.signaturesService.duplicateSignature(payload).pipe(
          switchMap(() => {
            return [new DuplicateSignatureSuccessAction(payload)];
          }),
          catchError(error => of(new DuplicateSignatureFailureAction(error)))
        );
      })
    );
  });

  duplicateSignatureSuccess$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(DUPLICATE_SIGNATURE_SUCCESS),
    map(() => {
      this.toastr.success(this.translate.instant('signatures.duplicateSuccess'));
      scrollToBottom();
      return new GetSignaturesAction();
    })
  ));

  reinstallSignatures$ = createEffect(() => this.actions$.pipe(
    ofType<ReinstallSignaturesAction>(REINSTALL_SIGNATURES),
    mergeMap(() =>
      this.signaturesService.reinstallSignatures().pipe(
        map(() => {
          this.toastr.success(this.translate.instant('signatures.reinstallSuccess'));
          return new ReinstallSignaturesSuccessAction();
        }),
        catchError(error => of(new ReinstallSignaturesFailureAction(error)))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private signaturesService: SignaturesService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
  }

}
