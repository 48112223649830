<mat-toolbar class="header">
  <span>{{ 'partner.admins.header' | translate: {number: (admins$ | async)?.length || 0} }}</span>

  <div>
    <button mat-raised-button
            (click)="openCreatePartnerAdminDialog()">{{ 'partner.admins.new' | translate }}
    </button>
  </div>
</mat-toolbar>


<app-partner-admin-table [data]="admins$"></app-partner-admin-table>
