import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PartnerService } from '../partner.service';
import { AddClientRequest } from '../../client/client.interface';
import { AddPartnerAdminRequest } from '../partner.admin.interface';
import { ToastrTranslatedService } from '../../shared/toastr-translated.service';

@Component({
  selector: 'app-partner-create-admin-dialog',
  templateUrl: './partner-create-admin-dialog.component.html',
  styleUrls: ['./partner-create-admin-dialog.component.scss']
})
export class PartnerCreateAdminDialogComponent {

  public sendingRequest = false;

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    public dialogRef: MatDialogRef<PartnerCreateAdminDialogComponent>,
    private partnerService: PartnerService,
    private toastr: ToastrTranslatedService
  ) {
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.sendingRequest = true;
    const value = this.form.value as AddPartnerAdminRequest;

    this.partnerService.addAdmin(value)
      .subscribe(result => {
        this.sendingRequest = false;
        this.toastr.success('partner.addSuccess');
        this.close(true);
      });

  }

  close(success?: boolean) {
    this.dialogRef.close(success);
  }
}
