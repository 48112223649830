import { Action } from '@ngrx/store';
import { PaginatedData } from '../shared/model/table';
import {
  EmployeeListItem,
  EmployeeListSearchParams,
  ChangeExcludedFromSignaturesParams,
  EmployeeRequest,
  Employee
} from './employees.interface';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';
export const SET_EMPLOYEES_PARAMS = 'SET_EMPLOYEES_PARAMS';
export const CLEAR_EMPLOYEES_PARAMS = 'CLEAR_EMPLOYEES_PARAMS';

export const CHANGE_EXCLUDED_FROM_SIGNATURES_SUCCESS = 'CHANGE_EXCLUDED_FROM_SIGNATURES_SUCCESS';
export const CHANGE_EXCLUDED_FROM_SIGNATURES_FAILURE = 'CHANGE_EXCLUDED_FROM_SIGNATURES_FAILURE';
export const CHANGE_EXCLUDED_FROM_SIGNATURES = 'CHANGE_EXCLUDED_FROM_SIGNATURES';

export const EXCLUDE_ALL_EMPLOYEES = '[Employees] Exclude All Employees';

export const SEND_EMPLOYEE_REQUEST_SUCCESS = 'SEND_EMPLOYEE_REQUEST_SUCCESS';
export const SEND_EMPLOYEE_REQUEST_FAILURE = 'SEND_EMPLOYEE_REQUEST_FAILURE';
export const SEND_EMPLOYEE_REQUEST = 'SEND_EMPLOYEE_REQUEST';

export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export const UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE = 'UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE';
export const UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE_SUCCESS = 'UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE_SUCCESS';
export const UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE_FAILURE = 'UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE_FAILURE';

export const REMOVE_SIGNATURE_TEMPLATE = 'REMOVE_SIGNATURE_TEMPLATE';
export const REMOVE_SIGNATURE_TEMPLATE_SUCCESS = 'REMOVE_SIGNATURE_TEMPLATE_SUCCESS';
export const REMOVE_SIGNATURE_TEMPLATE_FAILURE = 'REMOVE_SIGNATURE_TEMPLATE_FAILURE';

export const SYNC_GOOGLE_EMPLOYEES = 'SYNC_GOOGLE_EMPLOYEES';
export const SYNC_GOOGLE_EMPLOYEES_SUCCESS = 'SYNC_GOOGLE_EMPLOYEES_SUCCESS';
export const SYNC_GOOGLE_EMPLOYEES_FAILURE = 'SYNC_GOOGLE_EMPLOYEES_FAILURE';

export class GetEmployeesAction implements Action {
  readonly type = GET_EMPLOYEES;

  constructor(public payload: EmployeeListSearchParams) {
  }
}

export class GetEmployeesSuccessAction implements Action {
  readonly type = GET_EMPLOYEES_SUCCESS;

  constructor(public payload: PaginatedData<EmployeeListItem>) {
  }
}

export class GetEmployeesFailureAction implements Action {
  readonly type = GET_EMPLOYEES_FAILURE;

  constructor(public payload: any) {
  }
}

export class SetEmployeesParamsAction implements Action {
  readonly type = SET_EMPLOYEES_PARAMS;

  constructor(public payload: EmployeeListSearchParams) {
  }
}

export class ClearEmployeesParamsAction implements Action {
  readonly type = CLEAR_EMPLOYEES_PARAMS;

  constructor() {
  }
}

export class ChangeExcludedFromSignaturesAction implements Action {
  readonly type = CHANGE_EXCLUDED_FROM_SIGNATURES;

  constructor(public payload: ChangeExcludedFromSignaturesParams) {
  }
}

export class ChangeExcludedFromSignaturesSuccessAction implements Action {
  readonly type = CHANGE_EXCLUDED_FROM_SIGNATURES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class ChangeExcludedFromSignaturesFailureAction implements Action {
  readonly type = CHANGE_EXCLUDED_FROM_SIGNATURES_FAILURE;

  constructor(public payload: any) {
  }
}

export class ExcludeAllEmployeesAction implements Action {
  readonly type = EXCLUDE_ALL_EMPLOYEES;
  constructor(public payload: { filters: EmployeeListSearchParams, exclude: boolean }) {}
}

export class SendEmployeeRequestAction implements Action {
  readonly type = SEND_EMPLOYEE_REQUEST;

  constructor(public payload: EmployeeRequest) {
  }
}

export class SendEmployeeRequestSuccessAction implements Action {
  readonly type = SEND_EMPLOYEE_REQUEST_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SendEmployeeRequestFailureAction implements Action {
  readonly type = SEND_EMPLOYEE_REQUEST_FAILURE;

  constructor(public payload: any) {
  }
}

export class GetEmployeeAction implements Action {
  readonly type = GET_EMPLOYEE;

  constructor(public payload: string) {
  }
}

export class GetEmployeeSuccessAction implements Action {
  readonly type = GET_EMPLOYEE_SUCCESS;

  constructor(public payload: Employee) {
  }
}

export class GetEmployeeFailureAction implements Action {
  readonly type = GET_EMPLOYEE_FAILURE;

  constructor(public payload: any) {
  }
}

export class UpdateEmployeeAction implements Action {
  readonly type = UPDATE_EMPLOYEE;

  constructor(public payload: Employee) {
  }
}

export class UpdateEmployeeSuccessAction implements Action {
  readonly type = UPDATE_EMPLOYEE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UpdateEmployeeFailureAction implements Action {
  readonly type = UPDATE_EMPLOYEE_FAILURE;

  constructor(public payload: any) {
  }
}

export class GetCurrentUserAction implements Action {
  readonly type = GET_CURRENT_USER;

  constructor() {
  }
}

export class GetCurrentUserSuccessAction implements Action {
  readonly type = GET_CURRENT_USER_SUCCESS;

  constructor(public payload: Employee) {
  }
}

export class GetCurrentUserFailureAction implements Action {
  readonly type = GET_CURRENT_USER_FAILURE;

  constructor(public payload: any) {
  }
}

export class UpdateEmployeeAndInstallSignatureAction implements Action {
  readonly type = UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE;

  constructor(public payload: Employee) {
  }
}

export class UpdateEmployeeAndInstallSignatureSuccessAction implements Action {
  readonly type = UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UpdateEmployeeAndInstallSignatureFailureAction implements Action {
  readonly type = UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveSignatureTemplateAction implements Action {
  readonly type = REMOVE_SIGNATURE_TEMPLATE;
  constructor(public payload: any) { }
}

export class RemoveSignatureTemplateSuccessAction implements Action {
  readonly type = REMOVE_SIGNATURE_TEMPLATE_SUCCESS;
  constructor(public payload: any) { }
}

export class RemoveSignatureTemplateFailureAction implements Action {
  readonly type = REMOVE_SIGNATURE_TEMPLATE_FAILURE;
  constructor(public payload: any) { }
}

export class SyncGoogleEmployeesAction implements Action {
  readonly type = SYNC_GOOGLE_EMPLOYEES;

  constructor() {
  }
}

export class SyncGoogleEmployeesSuccessAction implements Action {
  readonly type = SYNC_GOOGLE_EMPLOYEES_SUCCESS;

  constructor() {
  }
}

export class SyncGoogleEmployeesFailureAction implements Action {
  readonly type = SYNC_GOOGLE_EMPLOYEES_FAILURE;

  constructor(public payload: any) {
  }
}

export type EmployeesActions =
  | GetEmployeesAction
  | GetEmployeesSuccessAction
  | GetEmployeesFailureAction
  | SetEmployeesParamsAction
  | ChangeExcludedFromSignaturesAction
  | ChangeExcludedFromSignaturesSuccessAction
  | ChangeExcludedFromSignaturesFailureAction
  | ExcludeAllEmployeesAction
  | SendEmployeeRequestAction
  | SendEmployeeRequestSuccessAction
  | SendEmployeeRequestFailureAction
  | GetEmployeeAction
  | GetEmployeeSuccessAction
  | GetEmployeeFailureAction
  | UpdateEmployeeAction
  | UpdateEmployeeSuccessAction
  | UpdateEmployeeFailureAction
  | GetCurrentUserAction
  | GetCurrentUserSuccessAction
  | GetCurrentUserFailureAction
  | UpdateEmployeeAndInstallSignatureAction
  | UpdateEmployeeAndInstallSignatureSuccessAction
  | UpdateEmployeeAndInstallSignatureFailureAction
  | RemoveSignatureTemplateAction
  | RemoveSignatureTemplateSuccessAction
  | RemoveSignatureTemplateFailureAction
  | SyncGoogleEmployeesAction
  | SyncGoogleEmployeesFailureAction
  | SyncGoogleEmployeesSuccessAction
  ;
