<mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="expand">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" class="mat-column-expand" [ngClass]="{'align-top': expandedUnitId === element.orgUnitPath, 'expand-margin': expandedUnitId === element.orgUnitPath}">
      <button mat-icon-button (click)="toggleMembers(element.orgUnitPath); $event.stopPropagation()">
        <mat-icon>{{ expandedUnitId === element.orgUnitPath ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'footers.unit' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="orgUnitPath">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'footers.path' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.orgUnitPath }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="directMembersCount">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'footers.memberCount' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="expandedUnitId !== element.orgUnitPath" class="cell-content">{{ element.directMembersCount }}</div>
      <div *ngIf="expandedUnitId === element.orgUnitPath">
        <mat-list>
          <mat-list-item *ngFor="let member of members" class="unit-members">
            {{ member }}
          </mat-list-item>
        </mat-list>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="assign">
    <mat-header-cell *matHeaderCellDef>{{'footers.set' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-raised-button (click)="assign(element.orgUnitPath); $event.stopPropagation()" [disabled]="updating">
        {{ (updating ? 'footers.setting' : 'footers.set') | translate }}
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleMembers(row.orgUnitPath)"></mat-row>
</mat-table>

<app-loading *ngIf="isLoading"></app-loading>