<div class="integration-row">

  <div *ngIf="lastGoogleSync" class="last-google-sync">
    <mat-icon>check</mat-icon>
    {{ 'settings.integration.lastGoogleSync' | translate: {lastGoogleSync: lastGoogleSyncFormatted } }}
  </div>


  <p>{{ 'settings.integration.description' | translate }} <a href="https://admin.google.com/ac/owl/domainwidedelegation"
                                                             target="_blank">(link)</a></p>

  <ol>
    <li>{{ 'settings.integration.instruction1' | translate }}
      <a href="https://admin.google.com/ac/owl/domainwidedelegation"
         target="_blank">(link)</a>
    </li>
    <li>{{ 'settings.integration.instruction2' | translate }}
      <a [routerLink]="['/employees']">(link)</a>

    </li>
    <li>{{ 'settings.integration.instruction3' | translate }}
      <a [routerLink]="['/settings', 'advanced']">(link)</a>
    </li>
  </ol>

  <div class="copy-box">
    <span class="coby-box-header">{{ 'settings.integration.clientId' | translate }}</span>
    <mat-icon (click)="copyToClipboard(clientId)">content_copy</mat-icon>
    <code>{{ clientId }}</code>
  </div>

</div>

<div class="integration-row">

  <div class="copy-box">
    <span class="coby-box-header">{{ 'settings.integration.scopes' | translate }}</span>
    <mat-icon (click)="copyToClipboard(scopes)">content_copy</mat-icon>
    <code>{{ scopes }}</code>
  </div>
</div>
