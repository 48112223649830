import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PartnerService } from '../partner.service';
import { PartnerCreateAdminDialogComponent } from '../partner-create-admin-dialog/partner-create-admin-dialog.component';
import { PartnerAdmin } from '../partner.admin.interface';

@Component({
  selector: 'app-partner-admins',
  templateUrl: './partner-admins.component.html',
  styleUrls: ['./partner-admins.component.scss']
})
export class PartnerAdminsComponent implements OnInit {
  public admins: PartnerAdmin[];
  public loading = true;

  public reloadAdmins = new ReplaySubject<number>(1);
  public admins$: Observable<PartnerAdmin[]>;

  constructor(private partnerService: PartnerService,
              private dialog: MatDialog
  ) {
  }

  openCreatePartnerAdminDialog() {
    this.dialog.open(PartnerCreateAdminDialogComponent)
      .afterClosed()
      .subscribe(() => {
        this.refreshAdmins();
      });
  }

  async ngOnInit() {
    this.admins$ = this.reloadAdmins.pipe(
      switchMap(() => this.partnerService.fetchAdmins())
    );
    this.refreshAdmins();
    this.admins$.subscribe((admins: PartnerAdmin[]) => {
      return this.admins = admins;
    });
  }

  private refreshAdmins = () => {
    this.reloadAdmins.next(1);
  };

}
