<mat-table [dataSource]="dataSource" >

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>{{ 'partner.email' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element" [title]="element.email">
      <span>{{ element.email}}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>

