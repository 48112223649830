<mat-toolbar class="header">
  <span>{{'planned.header' | translate}}</span>
  <a mat-raised-button [routerLink]="['/wizard']">{{'planned.new' | translate}}</a>
</mat-toolbar>

<div class="signatures-container">
<p>{{'planned.info' | translate}}</p>

<mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="templateName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'planned.name' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.templateName">{{ element.templateName }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateUtc">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'planned.date' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.dateUtc">{{ element.dateUtc | localizedDate}}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="context">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'planned.destination' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.context">{{toDestination(element.context) | translate}}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'planned.typeLabel' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.type">
        {{'planned.type.' + element.type | translate}}
        <div *ngIf="element.type === 'ABSENCE'">{{ element.dateUtc | localizedDate:'medium'}}</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cancel">
    <mat-header-cell *matHeaderCellDef>{{'cancel' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a
        mat-raised-button
        color="warn"
        (click)="remove(element.id)"
        *ngIf="element.type === 'PLANNER'"
      >
        {{'cancel' | translate}}
      </a>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
</div>
