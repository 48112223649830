import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {SignatureInstallationState} from '../signature-installation-state.interface';
import {getCurrentStateSelector} from '../signature-installation-state.reducer';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {
  SignatureInstallationErrorDialogComponent
} from '../signature-installation-error-dialog/signature-installation-error-dialog.component';
import moment from 'moment';
import {MY_FORMATS} from '../../shared/shared.module';


@Component({
  selector: 'app-signature-installation-progress-toast',
  templateUrl: './signature-installation-progress-toast.component.html',
  styleUrls: ['./signature-installation-progress-toast.component.scss']
})
export class SignatureInstallationProgressToastComponent implements AfterViewInit {
  @ViewChild('snackbarTemplate') template: TemplateRef<any>;
  public isOpen = false;

  public installedEmployeesCount = 0;
  public installedAliasesCount = 0; 
  public totalInstallations = 0;
  
  public currentState: SignatureInstallationState;
  public lastUpdate: string;

  constructor(private readonly store: Store,
              private readonly dialog: MatDialog,
              private readonly snackbar: MatSnackBar) {
  }

  ngAfterViewInit(): void {
    this.store.select(getCurrentStateSelector).subscribe(result => {
      this.currentState = result;
      this.lastUpdate = moment(new Date()).format(MY_FORMATS.display.time);
      
      if (this.currentState) {
        this.installedAliasesCount = this.currentState.installations.filter(i => i.isAlias === true).length;
        this.installedEmployeesCount = this.currentState.installations.filter(i => i.isAlias === false).length;

        this.totalInstallations = (this.currentState.totalEmployees || 0) + (this.currentState.totalAliases || 0);
      }
  
      if (!this.isOpen && result) {
        this.isOpen = true;
        this.snackbar.openFromTemplate(this.template);
        return;
      }

      if (!result) {
        this.isOpen = false;
        this.snackbar.dismiss();
      }
    });
  }

  get errors(): SignatureInstallationState['installations'] {
    return this.currentState.installations.filter(i => i.error);
  }

  close() {
    this.isOpen = false;
    this.snackbar.dismiss();
  }

  showErrors() {
    this.isOpen = false;
    this.snackbar.dismiss();
    this.dialog.open(SignatureInstallationErrorDialogComponent, {data: this.currentState});
  }
}
