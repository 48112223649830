<div *ngIf="employee" class="current-employee-wrapper">
  <mat-card appearance="outlined" class="signature">
    <mat-card-header>
      <mat-card-title>{{'employee.yourCurrentSignatureDescription' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="signature-wrapper">
        <div [innerHTML]="parsedSignature | safeHtml" class="signature-content"></div>
      </div>
      <div class="copy-signature">
        <a (click)="copySignatureToClipboard()">
          <mat-icon>content_copy</mat-icon>
          {{'employee.copySignature' | translate}}
        </a>
        <a (click)="downloadSignatureOutlook()">
          <mat-icon>download</mat-icon>
          {{ 'employee.downloadSignatureOutlook' | translate }}
        </a>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="settings">
    <mat-card-header>
      <mat-card-title>{{'employee.yourCurrentSignatureSettingsDescription' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
        <div *ngIf="settings.bannerDisablingByUser" class="banner-disabling">
          <mat-checkbox formControlName="bannerDisabled">
            {{ 'employee.bannerDisabled' | translate }}
          </mat-checkbox>
        </div>

        <div class="fields-wrapper">
          <mat-form-field appearance="fill">
            <mat-label>{{'employee.givenName' | translate}}</mat-label>
            <input matInput formControlName="givenName" type="text">
            <mat-error *ngIf="form.hasError('required', ['givenName'])">{{'fieldRequired' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{'employee.familyName' | translate}}</mat-label>
            <input matInput formControlName="familyName" type="text">
            <mat-error *ngIf="form.hasError('required', ['familyName'])">{{'fieldRequired' | translate}}</mat-error>
          </mat-form-field>

          <div [formGroup]="dynamicVariables" class="dynamic-fields">
            <mat-form-field appearance="fill" *ngFor="let employSetting of employeeSettings">
              <mat-label>{{employSetting.label || ('employee.signatureVariables.' + employSetting.name | translate)}}</mat-label>
              <input matInput [formControlName]="employSetting.name" type="text">
              <mat-error *ngIf="dynamicVariables.hasError('required', [employSetting.name])">
                {{'fieldRequired' | translate}}
              </mat-error>
              <mat-error *ngIf="dynamicVariables.hasError('phoneNumberInvalid', [employSetting.name])">
                {{'phoneNumberInvalid' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="links">
          <div class="links-header">
            <span>{{'employee.links.label' | translate}}</span>
            <button mat-mini-fab (click)="addLink()">
              <mat-icon class="add-course-btn">add</mat-icon>
            </button>
          </div>

          <div formArrayName="customLinks">
            <div *ngFor="let link of customLinks.controls; let i = index" class="links-form-row" [formGroup]="link">
              <mat-form-field appearance="fill">
                <mat-label>{{'employee.links.text' | translate}}</mat-label>
                <input matInput formControlName="text" type="text">
                <mat-error *ngIf="link.hasError('required', ['text'])">{{'fieldRequired' | translate}}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>{{'employee.links.url' | translate}}</mat-label>
                <input matInput formControlName="url" type="text">
                <mat-error *ngIf="link.hasError('required', ['url'])">{{'fieldRequired' | translate}}</mat-error>
                <mat-error *ngIf="link.hasError('urlInvalid', ['url'])">{{'urlInvalid' | translate}}</mat-error>
              </mat-form-field>

              <div class="delete-wrapper">
                <mat-icon class="delete-btn" (click)="removeLink(i)">delete_outline</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <mat-card appearance="outlined">
          <img mat-card-image [src]="employee.picture" alt="">
        </mat-card>

        <div class="actions">
          <a mat-raised-button (click)="fillForm(employee)">{{'cancel' | translate}}</a>
          <button type="submit" mat-raised-button color="primary">{{'saveAndInstall' | translate}}</button>
        </div>
      </form>

      <app-avatar-uploader 
        [avatarUploadUrl]="'/user/avatar'" 
        [thumbnailPhotoUrl]="employee.thumbnailPhotoUrl" 
        [localAvatar]="employee.localAvatar">
      </app-avatar-uploader>

    </mat-card-content>
  </mat-card>

</div>
