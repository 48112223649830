<img
  [src]="banner.imageUrl"
  [style]="imageService.getImageStyle(form.get('imageWidth').value)"
  alt=""
/>

<div *ngIf="!editMode" class="variables">
  {{'addons.banners.name' | translate}}: {{banner.name}}
  <br/>
  {{'addons.banners.url' | translate}}: {{banner.url}}
  <br/>
  {{'addons.banners.utm' | translate}}: {{banner.utm}}
  <br/>
  <span *ngIf="banner.modifiedAt">{{ 'modifiedAt' | translate }}: {{ banner.modifiedAt | localizedDate:'medium' }}</span>
</div>

<form [formGroup]="form" class="form" (ngSubmit)="changeBanner()" *ngIf="editMode">
  <span *ngIf="banner.modifiedAt">{{ 'modifiedAt' | translate }}: {{ banner.modifiedAt | localizedDate:'medium' }}</span>
  <mat-form-field appearance="fill">
    <app-image-uploader
      [allowedFileExtensions]="['image/jpeg', 'image/png', 'image/gif']"
      wrongTypeMessage="{{'addons.banners.wrongType' | translate}}"
      description="{{'addons.banners.description' | translate}}"
      additionalDescription="{{'addons.banners.additionalDescription' | translate}}"
      [previewImageStyles]="imageService.getImageStyle(form.get('imageWidth').value)"
      (onFileChange)="fileChange($event)"
    ></app-image-uploader>
    <input matInput formControlName="file" hidden="true">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'imageWidth' | translate}}</mat-label>
    <input matInput formControlName="imageWidth" type="number" min="0">
    <span matSuffix>px</span>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.banners.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.banners.url' | translate}}</mat-label>
    <input matInput formControlName="url" type="text">
    <mat-error *ngIf="form.hasError('required', ['url'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.banners.utm' | translate}}</mat-label>
    <input matInput formControlName="utm" type="text">
    <mat-error *ngIf="form.hasError('required', ['utm'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>
</form>

<mat-divider></mat-divider>
<div class="actions">
  <mat-icon *ngIf="editMode" title="{{'cancel' | translate}}" (click)="cancelEditBanner()">cancel</mat-icon>
  <mat-icon *ngIf="editMode" title="{{'save' | translate}}" (click)="changeBanner()">save</mat-icon>
  <mat-icon *ngIf="!editMode" title="{{'edit' | translate}}" (click)="changeEditMode()">edit</mat-icon>
  <mat-icon *ngIf="!editMode" title="{{'copyToClipboard' | translate}}" (click)="copyBannerToClipboard()">content_copy
  </mat-icon>
  <mat-icon *ngIf="!editMode && !banner.isUsed" title="{{'delete' | translate}}" (click)="removeBanner(banner.id)">delete_outline
  </mat-icon>
</div>
