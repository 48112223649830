import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddLinkObject, Links, ChangeLinkObject, Link } from './links.interface';
import { AddonsResponse, TypedAddon } from '../addons.interface';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LinksService {

  constructor(private http: HttpClient) {
  }

  getLinks = (): Observable<Links> => {
    return this.http.get<AddonsResponse>('/addons')
      .pipe(
        map((addons) => addons?.LINK?.map(link => ({
          id: link.id,
          modifiedAt: link.modifiedAt,
          isUsed: link.isUsed,
          ...link.data,
        } as Link))),
        map(links => ({ links }))
      );
  }

  getLinkById = (id: string): Observable<Link> => {
    return this.getLinks().pipe(
      map((links) => links.links.find((link) => link.id === id))
    );
  };

  changeLink = (body: ChangeLinkObject): Observable<any> => {
    return this.http.put(`/addons/${ body.id }`, body);
  }

  removeLink = (id: string): Observable<any> => {
    return this.http.delete(`/addons/${id}`);
  }

  addLink = (payload: AddLinkObject): Observable<Link> => {
    return this.http.post<TypedAddon<Link>>(`/addons`, payload)
      .pipe(
        map((link) => ({id: link.id, ...link.data}))
      );
  }

}
