import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {AddClientRequest, ClientWithStats} from '../client/client.interface';
import { endOfDay } from '../shared/utils/trial.util';
import { AddPartnerAdminRequest, PartnerAdmin } from './partner.admin.interface';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private httpClient: HttpClient) {
  }

  public fetchClients(): Observable<ClientWithStats[]> {
    return this.httpClient.get<ClientWithStats[]>('/partner/clients');
  }

  public addClient(req: AddClientRequest): Observable<unknown> {
    return this.httpClient.post('/partner/clients', {...req, trialFinishDate: endOfDay(req.trialFinishDate)});
  }

  public fetchAdmins(): Observable<PartnerAdmin[]> {
    return this.httpClient.get<PartnerAdmin[]>('/partner/admins');
  }

  public addAdmin(req: AddPartnerAdminRequest): Observable<unknown> {
    return this.httpClient.post('/partner/admins', req);
  }

}
